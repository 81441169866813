<template>
	<div class="sidenav">
		<div class="atvarbox">
			<img src="@/assets/images/index/avtar.jpg" alt="" class="avtar">
			<p class="userNickName">{{logins.loginInfo.company==''?logins.loginInfo.phone:logins.loginInfo.company}}</p>
		</div>
		<el-menu class=" sideUl" :default-active="routerpath" :router="true">
			<el-menu-item index="/person/addlist" class="li"> 收藏列表 </el-menu-item>
			<el-menu-item index="/person/management" class="li" v-show="logins.loginInfo.type === 2">IP管理 </el-menu-item>
			<el-menu-item index="/person/niche" class="li" v-show="logins.loginInfo.type === 2">商机响应 </el-menu-item>
			<el-menu-item index="/person/demand" class="li" v-show="logins.loginInfo.type === 1" >需求管理 </el-menu-item>
			<el-menu-item index="/person/baseinfo" class="li">信息认证</el-menu-item>
			<el-menu-item index="/person/infoset" class="li">安全设置</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	import useStore from '@/store/index.js'
	export default {
		data() {
			return {
				
			}
		},
		created(){
			this.store = useStore();
			this.logins = this.store.logins;
			console.log("logins",this.logins)
		},
		computed:{
			routerpath(){
				return this.$route.meta.activePath? this.$route.meta.activePath : this.$route.path
			}
		},
		mounted(){
		},

	}
</script>

<style scoped>
	.sidenav {flex: 0 0 196px;margin-right: 12px;}

	.atvarbox {width: 100%;height: 100px;background: #FFFFFF;border-radius: 4px;text-align: center;}

	.atvarbox .avtar {margin-top: 16px;}

	.atvarbox .userNickName {color: #0C0F1D;font-size: 14px;padding: 6px 12px 0;line-height: 18px;height: 42px;overflow: hidden;;}

	.sidenav .sideUl {background: #FFFFFF;border-radius: 4px;margin-top: 12px;height: 600px;border: none;}

	.sidenav .li {font-size: 14px;color: #2E3036;height: 44px;line-height: 44px;padding-left: 68px !important;display: block;}

	.sidenav .li:hover {background: rgba(245, 99, 11, 0.06);color: #EA5504;}

	.sidenav .li.is-active::before {content: '';width: 4px;height: 100%;position: absolute;left: 0;top: 0;background: #EA5504;;}

	.sidenav .li.is-active {background: rgba(245, 99, 11, 0.06);color: #EA5504;position: relative;}

	.sidenav .li {margin-bottom: 30px;}
</style>
